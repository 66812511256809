// src/index.ts
import { toTemporalInstant } from "@js-temporal/polyfill";

// src/datetime.ts
function newZonedDateTime({
  date,
  time,
  timeZone
}) {
  return date.toZonedDateTime({
    timeZone,
    plainTime: time
  });
}

// src/now.ts
import { Temporal } from "@js-temporal/polyfill";
function nowLocal(timeZone) {
  return now().toZonedDateTimeISO(timeZone);
}
function now() {
  return Temporal.Now.instant();
}
function todayLocal(timeZone) {
  return nowLocal(timeZone).toPlainDate();
}
var currentTime = (timeZone) => Temporal.Now.plainTimeISO(timeZone);

// src/comparison.ts
import { Temporal as Temporal2 } from "@js-temporal/polyfill";
function isToday(date, timeZone) {
  return Temporal2.PlainDate.compare(date, todayLocal(timeZone)) === 0;
}
function isTomorrow(date, timeZone) {
  return isToday(date.subtract({ days: 1 }), timeZone);
}
function isYesterday(date, timeZone) {
  return isToday(date.add({ days: 1 }), timeZone);
}
function isDateEqual(date, comparedWith) {
  return date.equals(comparedWith);
}
function isOptionalDateEqual(date, comparedWith) {
  if (date == null && comparedWith == null) {
    return true;
  } else if (date == null) {
    return false;
  } else if (comparedWith == null) {
    return false;
  } else {
    return isDateEqual(date, comparedWith);
  }
}
function isDateAfter(date, comparedWith) {
  return Temporal2.PlainDate.compare(date, comparedWith) === 1;
}
function isDateBefore(date, comparedWith) {
  return Temporal2.PlainDate.compare(date, comparedWith) === -1;
}
function isTimeAfter(time, comparedWith) {
  return Temporal2.PlainTime.compare(time, comparedWith) === 1;
}
function isTimeEqual(time, comparedWith) {
  return time.equals(comparedWith);
}
function isTimeBefore(time, comparedWith) {
  return Temporal2.PlainTime.compare(time, comparedWith) === -1;
}
function isInstantAfter(instant, comparedWith) {
  return Temporal2.Instant.compare(instant, comparedWith) === 1;
}
function isInstantEqual(instant, comparedWith) {
  return instant.equals(comparedWith);
}
function isOptionalInstantEqual(instant, comparedWith) {
  if (instant == null && comparedWith == null) {
    return true;
  } else if (instant == null) {
    return false;
  } else if (comparedWith == null) {
    return false;
  } else {
    return instant.equals(comparedWith);
  }
}
function isInstantBefore(instant, comparedWith) {
  return Temporal2.Instant.compare(instant, comparedWith) === -1;
}
function isDurationGreater(duration, comparedWith) {
  return Temporal2.Duration.compare(duration, comparedWith) === 1;
}
function isDurationEqual(duration, comparedWith) {
  return Temporal2.Duration.compare(duration, comparedWith) === 0;
}
function isDurationLess(duration, comparedWith) {
  return Temporal2.Duration.compare(duration, comparedWith) === -1;
}

// src/conversion.ts
import { Temporal as Temporal3 } from "@js-temporal/polyfill";
function toJSDateUTC(dt) {
  return new Date(dt.epochMilliseconds);
}
function toOptionalJSDateUTC(dt) {
  if (!dt)
    return void 0;
  return toJSDateUTC(dt);
}
function toPlainDate(date, timeZone) {
  if (timeZone) {
    return toInstant(date).toZonedDateTimeISO(timeZone).toPlainDate();
  }
  return Temporal3.PlainDate.from({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate()
  });
}
function toOptionalPlainDate(date) {
  if (!date)
    return void 0;
  return toPlainDate(date);
}
function toInstant(date) {
  return date.toTemporalInstant();
}
function toOptionalInstant(date) {
  if (!date)
    return void 0;
  return toInstant(date);
}
function toZonedDateTimeUTC(date) {
  return date.toTemporalInstant().toZonedDateTimeISO("UTC");
}
function toOptionalZonedDateTimeUTC(date) {
  if (!date)
    return void 0;
  return toZonedDateTimeUTC(date);
}
function plainDateToJSDate(date) {
  return toJSDateUTC(date.toZonedDateTime({
    timeZone: "UTC"
  }));
}
function plainDateTimeToJSDate(date) {
  return toJSDateUTC(date.toZonedDateTime("UTC"));
}
function plainDateToOptionalJSDate(date) {
  if (!date)
    return void 0;
  return plainDateToJSDate(date);
}
function plainDateTimeToOptionalJSDate(date) {
  if (!date)
    return void 0;
  return plainDateTimeToJSDate(date);
}
function instantToPlainDate(date, timezone) {
  return date.toZonedDateTimeISO(timezone).toPlainDate();
}
function instantToOptionalPlainDate(date, timezone) {
  if (!date)
    return void 0;
  return instantToPlainDate(date, timezone);
}
function instantToPlainDateTime(date, timezone) {
  return date.toZonedDateTimeISO(timezone).toPlainDateTime();
}
function instantToPlainTime(date, timezone) {
  return date.toZonedDateTimeISO(timezone).toPlainTime();
}
function durationToProtoDuration(duration) {
  const {
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
    microseconds,
    nanoseconds
  } = duration;
  const weeks = Math.floor(duration.total({ unit: "weeks", relativeTo: nowLocal("UTC") }));
  return {
    seconds: BigInt(60 * 60 * 24 * 7) * BigInt(weeks) + BigInt(60 * 60 * 24) * BigInt(days) + BigInt(60 * 60) * BigInt(hours) + BigInt(60) * BigInt(minutes) + BigInt(seconds),
    nanos: milliseconds * 1e6 + microseconds * 1e3 + nanoseconds
  };
}

// src/parsing.ts
import { Temporal as Temporal4 } from "@js-temporal/polyfill";
function parsePlainDate(iso8601DateStr) {
  return Temporal4.PlainDate.from(iso8601DateStr);
}
function parseOptionalPlainDate(iso8601DateStr) {
  if (!iso8601DateStr)
    return void 0;
  return parsePlainDate(iso8601DateStr);
}
function parsePlainDateTime(iso8601DateTimeStr) {
  return Temporal4.PlainDateTime.from(iso8601DateTimeStr);
}
function parseOptionalPlainDateTime(iso8601DateTimeStr) {
  if (!iso8601DateTimeStr)
    return void 0;
  return parsePlainDateTime(iso8601DateTimeStr);
}
function parseZonedDateTime(iso8601DateTimeStr) {
  return Temporal4.ZonedDateTime.from(iso8601DateTimeStr);
}
function parseOptionalZonedDateTime(iso8601DateStr) {
  if (!iso8601DateStr)
    return void 0;
  return parseZonedDateTime(iso8601DateStr);
}
function parseInstant(iso8601DateTimeStr) {
  return Temporal4.Instant.from(iso8601DateTimeStr);
}
function parseInstantFromEpochMilliseconds(epochMilliseconds) {
  return Temporal4.Instant.fromEpochMilliseconds(epochMilliseconds);
}
function parseOptionalInstant(iso8601DateStr) {
  if (!iso8601DateStr)
    return void 0;
  return parseInstant(iso8601DateStr);
}
function parsePlainTime(timeStr) {
  return Temporal4.PlainTime.from(timeStr);
}
function parseOptionalPlainTime(timeStr) {
  if (!timeStr)
    return void 0;
  return parsePlainTime(timeStr);
}

// src/formatting.ts
import ordinal from "ordinal";
function formatPlainDateTime(pd) {
  return pd.toString({
    smallestUnit: "minute"
  });
}
function formatPlainDate(pd) {
  return pd.toString();
}
var monthLongFormatter = typeof Intl === "object" && typeof Intl.DateTimeFormat === "function" ? new Intl.DateTimeFormat(void 0, {
  month: "long"
}) : null;
function getMonthFromPlainDate(pd) {
  if (!monthLongFormatter) {
    throw new Error("Intl.DateTimeFormat is not supported in this environment");
  }
  return monthLongFormatter.format(plainDateToJSDate(pd));
}
var monthShortFormatter = typeof Intl === "object" && typeof Intl.DateTimeFormat === "function" ? new Intl.DateTimeFormat(void 0, {
  month: "short"
}) : null;
function getShortMonthFromPlainDate(pd) {
  if (!monthShortFormatter) {
    throw new Error("Intl.DateTimeFormat is not supported in this environment");
  }
  return monthShortFormatter.format(plainDateToJSDate(pd));
}
function formatJSDate(date) {
  return toPlainDate(date).toString();
}
function formatPlainTime(pd) {
  return pd.toString({
    smallestUnit: "minute"
  });
}
function formatInstant(pd) {
  return pd.toString({
    timeZone: "UTC"
  });
}
function formatOptionalPlainDateTime(pd) {
  if (!pd)
    return void 0;
  return formatPlainDateTime(pd);
}
function formatOptionalPlainDate(pd) {
  if (!pd)
    return void 0;
  return formatPlainDate(pd);
}
function formatOptionalJSDate(date) {
  if (!date)
    return void 0;
  return formatJSDate(date);
}
function formatOptionalPlainTime(pd) {
  if (!pd)
    return void 0;
  return formatPlainTime(pd);
}
function formatFriendlyTimezone(timezone) {
  return nowLocal(timezone).toLocaleString("default", {
    day: "2-digit",
    timeZoneName: "long"
  }).substring(4);
}
function formatRelativeIso8601DateTimeStr(datetime) {
  const instant = parseInstant(datetime);
  return formatRelativeInstant(instant);
}
function formatOptionalRelativeIso8601DateTimeStr(datetime) {
  if (!datetime)
    return void 0;
  return formatRelativeIso8601DateTimeStr(datetime);
}
var rtf = typeof Intl === "object" && typeof Intl.RelativeTimeFormat === "function" ? new Intl.RelativeTimeFormat(void 0, { numeric: "always" }) : null;
var rtfNumeric = typeof Intl === "object" && typeof Intl.RelativeTimeFormat === "function" ? new Intl.RelativeTimeFormat(void 0, {
  numeric: "auto"
}) : null;
function formatRelativeInstant(instant) {
  if (!rtf || !rtfNumeric) {
    throw new Error("Intl.RelativeTimeFormat is not supported in this environment");
  }
  const duration = instant.since(now());
  const units = [
    "years",
    "months",
    "weeks",
    "days",
    "hours",
    "minutes",
    "seconds"
  ];
  for (const unit of units) {
    const num = duration.total({ unit, relativeTo: nowLocal("UTC") });
    const d = Math.trunc(num);
    if (d !== 0) {
      return rtf.format(d, unit);
    }
  }
  return rtfNumeric.format(0, "seconds");
}
function formatFriendlyIso8601DateTimeStr(datetime) {
  const instant = parseInstant(datetime);
  return formatFriendlyInstant(instant);
}
function formatFriendlyInstant(instant) {
  return instant.toLocaleString([], {
    hour: "numeric",
    minute: "numeric",
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric"
  });
}
function formatIso8601ToTime(time, tz) {
  return formatInstantTime(parseInstant(time), tz);
}
function formatIso8601ToFriendlyDate(time, tz) {
  return formatFriendlyPlainDate(instantToPlainDate(parseInstant(time), tz));
}
function formatIso8601ToFriendlyDateShort(time, tz) {
  return formatFriendlyPlainDateShort(instantToPlainDate(parseInstant(time), tz));
}
function formatOptionalIso8601ToTime(time, tz) {
  if (!time) {
    return null;
  }
  return formatInstantTime(parseInstant(time), tz);
}
function formatOptionalIso8601ToTimeWithSeconds(time, tz) {
  if (!time) {
    return null;
  }
  return formatInstantTimeWithSeconds(parseInstant(time), tz);
}
function formatFriendlyPlainDate(date) {
  return date.toLocaleString([], {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric"
  });
}
function formatFriendlyPlainDateShort(date, options = {}) {
  return date.toLocaleString([], {
    weekday: options.excludeWeekday ? void 0 : "short",
    month: "long",
    day: "numeric",
    year: options.includeYear ? "numeric" : void 0
  });
}
function formatPlainDateRange(date1, date2, options = {}) {
  var _a;
  const monthFormat = (_a = options.monthFormat) != null ? _a : "long";
  const start = date1.toLocaleString([], {
    month: date1.month === date2.month ? void 0 : monthFormat,
    day: "numeric",
    year: date1.year === date2.year ? void 0 : options.includeYear ? "numeric" : void 0
  });
  const end = date2.toLocaleString([], {
    month: monthFormat,
    day: "numeric",
    year: options.includeYear ? "numeric" : void 0
  });
  return `${start} - ${end}`;
}
function formatFriendlyDateTime(date) {
  return date.toLocaleString([], {
    hour: "numeric",
    minute: "numeric",
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric"
  });
}
function formatInstantFriendlyDate(time, tz) {
  return formatFriendlyDateTime(time.toZonedDateTimeISO(tz));
}
function formatInstantTime(time, tz) {
  return time.toZonedDateTimeISO(tz).toLocaleString([], { hour: "2-digit", minute: "2-digit" });
}
function formatInstantTimeWithSeconds(time, tz) {
  return time.toZonedDateTimeISO(tz).toLocaleString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit"
  });
}
function formatOptionalInstantTimeWithSeconds(time, tz) {
  if (!time) {
    return void 0;
  }
  return formatInstantTimeWithSeconds(time, tz);
}
function formatInstantTime24h(time, tz) {
  return time.toZonedDateTimeISO(tz).toLocaleString([], {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23"
  });
}
function formatHourMinute(date) {
  return date.toLocaleString([], {
    hour: "numeric",
    minute: "numeric"
  });
}
function formatDateAndTimeForTracking(dateTime, lowercase = true) {
  const time = dateTime.toLocaleString("default", {
    hour: "numeric",
    minute: "numeric"
  });
  const formattedDate = _formatDateForTracking(dateTime.toPlainDate(), dateTime.timeZoneId.toString(), lowercase);
  const hasTime = dateTime.hour !== 0 || dateTime.minute !== 0;
  return `${formattedDate}${hasTime ? ` at ${time}` : ""}`;
}
function formatDateForTracking(date, timezone, {
  prefix,
  smartPrefix,
  detailedDate,
  lowercase
} = {}) {
  const formatted = _formatDateForTracking(date, timezone, lowercase != null ? lowercase : true, detailedDate);
  if (!prefix) {
    return formatted;
  }
  if (!smartPrefix) {
    return `${prefix} ${formatted}`;
  }
  if (formatted.startsWith("today") || formatted.startsWith("tomorrow") || formatted.startsWith("yesterday")) {
    return formatted;
  } else {
    return `${prefix} ${formatted}`;
  }
}
function formatPlainDateForTracking(date) {
  const dom = date.toLocaleString("default", {
    day: "numeric"
  });
  const day = parseInt(dom);
  return `${date.toLocaleString(void 0, {
    weekday: "long"
  })}, ${date.toLocaleString("default", {
    month: "short"
  })} ${ordinal(day)}`;
}
function _formatDateForTracking(date, timezone, lowercase = true, detailedDate = false) {
  const dom = date.toZonedDateTime(timezone).toLocaleString("default", {
    day: "numeric"
  });
  const day = parseInt(dom);
  const detailFormatted = `${date.toZonedDateTime(timezone).toLocaleString(void 0, {
    weekday: "long"
  })}, ${date.toZonedDateTime(timezone).toLocaleString("default", {
    month: "short"
  })} ${ordinal(day)}`;
  const today = todayLocal(timezone);
  const diffDays = date.since(today).total({ unit: "days" });
  let friendlyDate = "";
  switch (diffDays) {
    case 0:
      friendlyDate = lowercase ? "today" : "Today";
      break;
    case 1:
      friendlyDate = lowercase ? "tomorrow" : "Tomorrow";
      break;
    case -1:
      friendlyDate = lowercase ? "yesterday" : "Yesterday";
      break;
    default:
      if (date.weekOfYear === today.weekOfYear && isDateAfter(date, today)) {
        friendlyDate = date.toZonedDateTime(timezone).toLocaleString("default", {
          weekday: "long"
        });
      }
  }
  if (detailedDate && friendlyDate) {
    return `${friendlyDate} (${detailFormatted})`;
  } else if (friendlyDate) {
    return friendlyDate;
  }
  return detailFormatted;
}
function formatLiveETA({
  liveEstimatedTimeLocal,
  driverName,
  timeWindowStart,
  timezone
}) {
  const now2 = nowLocal(timezone);
  const liveEstimatedMins = liveEstimatedTimeLocal ? liveEstimatedTimeLocal.since(now2).total({ unit: "minutes" }) : null;
  const earlyMins = timeWindowStart && liveEstimatedTimeLocal ? timeWindowStart == null ? void 0 : timeWindowStart.since(liveEstimatedTimeLocal).total({ unit: "minutes" }) : 0;
  const timeWindowStartMins = timeWindowStart ? timeWindowStart.since(now2).total({ unit: "minutes" }) : null;
  const isEarly = timeWindowStart && earlyMins > 0;
  if (liveEstimatedTimeLocal && liveEstimatedMins && liveEstimatedMins < 60) {
    return isEarly && timeWindowStartMins ? `${driverName} will be with you in ${timeWindowStartMins < 5 ? "a few mins" : `${timeWindowStartMins == null ? void 0 : timeWindowStartMins.toFixed()} mins (at ${formatPlainTime(timeWindowStart == null ? void 0 : timeWindowStart.toPlainTime())})`}` : `${driverName} is ${liveEstimatedMins < 5 ? "a few mins" : `${liveEstimatedMins.toFixed()} mins`} away`;
  }
  if (liveEstimatedTimeLocal) {
    return `${driverName}\u02BCs ETA is ${formatPlainTime(isEarly ? timeWindowStart.toPlainTime() : liveEstimatedTimeLocal.toPlainTime())}`;
  }
  return "";
}
function formatExternalLiveETA({
  liveEstimatedTimeLocal,
  driverName,
  timeWindowStart
}) {
  const earlyMins = timeWindowStart && liveEstimatedTimeLocal ? timeWindowStart == null ? void 0 : timeWindowStart.since(liveEstimatedTimeLocal).total({ unit: "minutes" }) : 0;
  const isEarly = timeWindowStart && earlyMins > 0;
  if (liveEstimatedTimeLocal) {
    return `${driverName}\u02BCs ETA is ${formatPlainTime(isEarly ? timeWindowStart.toPlainTime() : liveEstimatedTimeLocal.toPlainTime())}`;
  }
  return "";
}

// src/timezone.ts
var TZ_EUROPE_LONDON = "Europe/London";

// src/calculations.ts
import { Temporal as Temporal5 } from "@js-temporal/polyfill";
function addBusinessDays(date, n) {
  let next = date;
  for (let i = 1; i <= n; i += 1) {
    next = next.add({ days: 1 });
    if (next.dayOfWeek === 6 || next.dayOfWeek === 7) {
      next = next.add({ days: 8 - next.dayOfWeek });
    }
  }
  return next;
}
function startOfDay(date, timeZone) {
  return date.toZonedDateTime({
    timeZone,
    plainTime: new Temporal5.PlainTime()
  });
}
function endOfDay(date, timeZone) {
  return date.toZonedDateTime({
    timeZone,
    plainTime: new Temporal5.PlainTime().subtract({ nanoseconds: 1 })
  });
}
function startOfWeek(date) {
  return date.subtract({ days: date.dayOfWeek - 1 });
}
function endOfWeek(date) {
  return date.add({ days: 7 - date.dayOfWeek });
}
function startOfMonth(date) {
  return date.subtract({ days: date.day - 1 });
}
function endOfMonth(date) {
  return date.add({ days: date.daysInMonth - date.day });
}

// src/index.ts
Date.prototype.toTemporalInstant = toTemporalInstant;
export {
  TZ_EUROPE_LONDON,
  addBusinessDays,
  currentTime,
  durationToProtoDuration,
  endOfDay,
  endOfMonth,
  endOfWeek,
  formatDateAndTimeForTracking,
  formatDateForTracking,
  formatExternalLiveETA,
  formatFriendlyDateTime,
  formatFriendlyInstant,
  formatFriendlyIso8601DateTimeStr,
  formatFriendlyPlainDate,
  formatFriendlyPlainDateShort,
  formatFriendlyTimezone,
  formatHourMinute,
  formatInstant,
  formatInstantFriendlyDate,
  formatInstantTime,
  formatInstantTime24h,
  formatInstantTimeWithSeconds,
  formatIso8601ToFriendlyDate,
  formatIso8601ToFriendlyDateShort,
  formatIso8601ToTime,
  formatJSDate,
  formatLiveETA,
  formatOptionalInstantTimeWithSeconds,
  formatOptionalIso8601ToTime,
  formatOptionalIso8601ToTimeWithSeconds,
  formatOptionalJSDate,
  formatOptionalPlainDate,
  formatOptionalPlainDateTime,
  formatOptionalPlainTime,
  formatOptionalRelativeIso8601DateTimeStr,
  formatPlainDate,
  formatPlainDateForTracking,
  formatPlainDateRange,
  formatPlainDateTime,
  formatPlainTime,
  formatRelativeInstant,
  formatRelativeIso8601DateTimeStr,
  getMonthFromPlainDate,
  getShortMonthFromPlainDate,
  instantToOptionalPlainDate,
  instantToPlainDate,
  instantToPlainDateTime,
  instantToPlainTime,
  isDateAfter,
  isDateBefore,
  isDateEqual,
  isDurationEqual,
  isDurationGreater,
  isDurationLess,
  isInstantAfter,
  isInstantBefore,
  isInstantEqual,
  isOptionalDateEqual,
  isOptionalInstantEqual,
  isTimeAfter,
  isTimeBefore,
  isTimeEqual,
  isToday,
  isTomorrow,
  isYesterday,
  newZonedDateTime,
  now,
  nowLocal,
  parseInstant,
  parseInstantFromEpochMilliseconds,
  parseOptionalInstant,
  parseOptionalPlainDate,
  parseOptionalPlainDateTime,
  parseOptionalPlainTime,
  parseOptionalZonedDateTime,
  parsePlainDate,
  parsePlainDateTime,
  parsePlainTime,
  parseZonedDateTime,
  plainDateTimeToJSDate,
  plainDateTimeToOptionalJSDate,
  plainDateToJSDate,
  plainDateToOptionalJSDate,
  startOfDay,
  startOfMonth,
  startOfWeek,
  toInstant,
  toJSDateUTC,
  toOptionalInstant,
  toOptionalJSDateUTC,
  toOptionalPlainDate,
  toOptionalZonedDateTimeUTC,
  toPlainDate,
  toZonedDateTimeUTC,
  todayLocal
};
